$white: #fff;

$vangolu:  #1B6475;
$ephemic:  #589FA3;
$serenity: #ADD8C7;
$cachaca:  #E3FAF1;
$winter:   #FDFDFD;

$vangolu-accent:  #5a5495;
$ephemic-accent:  #918add;
$serenity-accent: #cdc9ff;
$cachaca-accent:  #f5f4fa;

.bg-white { background-color: $white; }
.bg-vangolu { background-color: $vangolu; }
.bg-ephemic { background-color: $ephemic; }
.bg-serenity { background-color: $serenity; }
.bg-cachaca { background-color: $cachaca; }
.bg-winter { background-color: $winter; }
.bg-vangolu-accent { background-color: $vangolu-accent; }
.bg-ephemic-accent { background-color: $ephemic-accent; }
.bg-serenity-accent { background-color: $serenity-accent; }
.bg-cachaca-accent { background-color: $cachaca-accent; }
